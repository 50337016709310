import styled from 'styled-components';
import { rem } from 'polished';

interface StyledGridProps {
  inverted: Boolean;
}

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: dense;
  grid-gap: ${rem(`24px`)};
  grid-template-columns: repeat(2, 1fr);
  h5 {
    color: ${({ theme }) => theme.colors.yellow_1};
    margin-right: ${rem(`12px`)};
  }
  ${({ theme }) => theme.breakpoints.phone`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${({ inverted, theme }) =>
    inverted &&
    theme.breakpoints.tablet`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-flow: unset;
      grid-gap: 0;
      grid-template-columns: unset;
      grid-auto-rows: max-content;
      div {
        display: flex;
        align-items: center;
        margin: 0;
        margin-right: ${rem(`6px`)};
      }
      && .listItem__icon {
        margin: 0;
        margin-right: ${rem(`3px`)};
      }
      && .last-item {
        margin: 0;
      }
      h5 {
        &:after {
          content: ':';
        }
      }
      ${theme.breakpoints.desktop`
        grid-template-columns: repeat(1, 1fr);
      `}
    `}
`;
