import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledProject {
  theme: any;
}

export const StyledProject = styled.div<IStyledProject>`
  .link {
    margin-left: ${rem(`8px`)};
  }
  .team-members section:first-child {
    margin-left: 0;
  }
`;
