import React from 'react';
import { Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { StyledGrid } from './styles';
import { ListItem } from '../ListItem';
import { Text } from '../Text';

interface IGrid {
  data?: any;
  className?: string;
  inverted?: boolean;
}

export const Grid = ({ data, className, inverted }: IGrid) => (
  <StyledGrid className={className} inverted={inverted}>
    {data.map(({ techs, title }) => {
      const keyIndex = uuidv4();
      return (
        <div key={`parent-${keyIndex}}`}>
          <Text
            type="body1"
            tag="h5"
            color="white_1"
            style={{ marginBottom: `8px` }}
          >
            {title}
          </Text>
          {techs.map(({ name, link }, tIdx, nestedArray) => {
            const innerKeyIdx = uuidv4();
            return (
              <ListItem
                key={`child-${keyIndex}-${innerKeyIdx}`}
                contrast={false}
                className={tIdx === nestedArray.length - 1 ? `last-item` : ``}
              >
                {link.includes(`http`) ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={link}
                    href={link}
                  >
                    {name}
                  </a>
                ) : (
                  <Link target="_blank" aria-label={link} to={link}>
                    {name}
                  </Link>
                )}
              </ListItem>
            );
          })}
        </div>
      );
    })}
  </StyledGrid>
);
